@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

@layer base {
  .font-outline-1 {
    background: -webkit-linear-gradient(-66deg, #FF4DF0 50%, #045DED 50%);
    -webkit-background-clip: text;
    -webkit-text-stroke: 2px transparent;
  }

  .bubble-button {
    color: white;
    border: #E97DB1;
    border-style: solid;
    border-radius: 9999px;
    padding: 10px 25px 10px 25px;
    animation: slidein, hide-scroll;
    animation-duration: 3s;
  }

  .slidein {
    position: relative;
    animation: slidein, hide-scroll;
    animation-duration: 3s;
    overflow: hidden;
  }

  .slideup {
    position: relative;
    animation: slideup, hide-scroll;
    animation-duration: 3s;
    overflow: hidden;
  }

  .slidedown {
    position: relative;
    animation: bubble-popin, hide-scroll;
    animation-duration: 3s;
  }

  .disappear {
    animation: disappear 1s 1 linear forwards;
  }
}

@keyframes disappear{
  from{
    opacity: 100%;
  }
  to{
    opacity: 0%;
  }
}

@keyframes bubble-popin{
  from{
    top:-2500px;
  }
  to{
    top: 0px;
  }
}

@keyframes slideup{
  from{
    top:2500px;
  }
  to{
    top: 0px;
  }
}

@keyframes hide-scroll {
  from {
    overflow: hidden;
  }
  to {
    overflow: auto;
  }

}

@keyframes slidein {
  from {
    left: 2500px;
  }
  to {
    left: 0px;
  }
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.white-svg {
  filter: invert(1) grayscale(100%) brightness(800%);
}

/* Add this to your CSS file */
.fade-out {
  transition: opacity 0.5s ease-out;
}

.visible {
  opacity: 1;
}

.hidden {
  opacity: 0;
  pointer-events: none; /* optional: disable interactions on hidden element */
}

/* Add this to your CSS file */
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px; /* Adjust padding as needed */
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow */
}

/* Mobile Styles */
@media screen and (max-width: 640px) {
  .mx-60 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .grid-cols-1 {
    grid-template-columns: 1fr; /* Adjust for smaller screens */
  }

  .text-2xl {
    font-size: 1.5rem; /* Adjust font size for smaller screens */
  }
}
